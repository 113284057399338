import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import customMedia from '../../../utils/customMedia'

const BannerH6 = ({ children, style }) => (
  <Wrapper style={style}>{children}</Wrapper>
)

BannerH6.propTypes = {
  style: PropTypes.object,
}

export default BannerH6

const Wrapper = styled.h6`
  font-weight: 300;
  line-height: 1.5;
  font-size: 14px;
  text-align: center;

  ${customMedia.between('mobile', 'tablet')`
    font-size: 16px;
  `};

  ${customMedia.greaterThan('desktop')`
    font-size: 16px;
  `};
`
