import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Boxes from './boxes'
import brandsData from '../../data/brand'
import arrowLeftUp from '../../images/share/arrow-left-up.svg'

import customMedia from '../../utils/customMedia'

const getBrandData = () => {
  const items = []

  while (items.length < 4) {
    const item = brandsData[Math.floor(Math.random() * brandsData.length)]
    const savedItem = items.find(i => i.brandName === item.brandName)

    if (!savedItem) {
      items.push(item)
    }
  }

  return items
}

const BrandBanner = ({ text }) => {
  return (
    <Wrapper>
      <Boxes
        name="brands"
        items={getBrandData()}
        rowItems="4"
        boxMargin="5px"
        adjustHeight="100px"
        iconSize="contain"
        iconLength="90%"
        background="#ffffff"
        iconColor="brightness(0) saturate(100%) invert(77%) sepia(0%) saturate(39%) hue-rotate(86deg) brightness(101%) contrast(100%)"
        hover={false}
      />

      {text ? (
        <BannerText>
          <img src={arrowLeftUp} />
          {text}
        </BannerText>
      ) : (
        <CaseLink to="/case-studies">
          Read about our customer’s success....
        </CaseLink>
      )}
    </Wrapper>
  )
}

BrandBanner.propTypes = {
  text: PropTypes.string,
}

export default BrandBanner

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const BannerText = styled.p`
  display flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 70%;
  font-family: Caveat;
  font-size: 20px;
  color: #02b7e2;
  text-align: center;

  img {
    margin-right: 10px;
    margin-bottom: 15px;
  }

  ${customMedia.greaterThan('mobile')`
    width: 100%;
`};
`

const CaseLink = styled(Link)`
  margin-top: 20px;
  width: 100%;
  font-family: Caveat;
  font-size: 20px;
  color: #02b7e2;
  text-decoration: none;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }
`
