import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

import customMedia from '../../utils/customMedia'

const query = graphql`
  query {
    BarryPlant: file(relativePath: { eq: "brandLogos/barryPlant.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    BelleProperty: file(relativePath: { eq: "brandLogos/belleProperty.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    Cunninghams: file(relativePath: { eq: "brandLogos/cunninghams.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    McconnellBourn: file(
      relativePath: { eq: "brandLogos/mcconnellBourn.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    LJHooker: file(relativePath: { eq: "brandLogos/ljHooker.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    TheAgency: file(relativePath: { eq: "brandLogos/theAgency.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    grantCordone: file(relativePath: { eq: "brandLogos/grantCordone.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

const Quote = props => {
  const {
    agencyName,
    agentImage,
    agentName,
    desc,
    descStyle,
    style,
    title,
    imgStyle,
  } = props

  return (
    <StaticQuery
      query={query}
      render={data => {
        const agencyImage = data[agencyName]

        return (
          <FlexColumn style={style}>
            <Desc style={descStyle}>{desc}</Desc>
            <Flex>
              {agentImage && <AgentImage fluid={agentImage} />}
              <FlexColumn>
                <Flex>
                  <AgentName>{agentName}</AgentName>
                  <Title>{title}</Title>
                </Flex>

                {agencyImage ? (
                  <AgencyImg
                    fluid={agencyImage.childImageSharp.fluid}
                    style={imgStyle}
                    imgStyle={{
                      objectFit: 'contain',
                      objectPosition: 'left center',
                    }}
                  />
                ) : (
                  <AgencyName>{agencyName}</AgencyName>
                )}
              </FlexColumn>
            </Flex>
          </FlexColumn>
        )
      }}
    />
  )
}

Quote.propTypes = {
  agencyImage: PropTypes.object,
  agencyName: PropTypes.string,
  agentName: PropTypes.string,
  desc: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  descStyle: PropTypes.object,
  style: PropTypes.object,
  title: PropTypes.string,
}

export default Quote

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const Flex = styled.div`
  display: flex;
  align-items: center;
`

const Desc = styled.div`
  font-family: Caveat;
  color: #02b7e2;
  font-size: 20px;
  margin-bottom: 20px;
  letter-spacing: 1px;

  ${customMedia.greaterThan('mobile')`
    font-size: 22px;
    width: 75%;
  `};
`

const AgencyImg = styled(Img)`
  width: 60px;
  height: 25px;
`

const AgentImage = styled(Img)`
  width: 75px;
  height: 75px;
  border-radius: 50%;
  margin-right: 10px;
`
const AgentName = styled.div`
  font-size: 14px;
  margin-right: 5px;
`
const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
`
const AgencyName = styled.div`
  font-size: 12px;
  margin-top: 5px;
  letter-spacing: 1px;
  font-weight: 300;
`
