import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  fab,
  faFacebook,
  faLinkedin,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'
import {
  faArrowCircleRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faBoxUsd,
  faCodeBranch,
  faFileInvoiceDollar,
  faHeadset,
  faHome,
  faMobile,
  faPaintBrush,
  faPalette,
  faShoppingBasket,
  faThumbsUp,
  faLongArrowDown,
  faUsers,
  faCopyright,
  faDownload,
  faSignIn,
  faLifeRing,
  faBars,
  faTimes,
  faMobileAndroid,
  faWindowClose,
} from '@fortawesome/pro-light-svg-icons'

import Navbar from './share/navbar'
import Footer from './share/footer'

import appleTouchIcon from '../images/favicon/apple-touch-icon.png'
import icon32 from '../images/favicon/favicon-32x32.png'
import icon16 from '../images/favicon/favicon-16x16.png'
import maskIcon from '../images/favicon/safari-pinned-tab.svg'

import './reset.css'

library.add(
  faArrowCircleRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faBoxUsd,
  faCodeBranch,
  faFileInvoiceDollar,
  faHeadset,
  faHome,
  faMobile,
  faMobileAndroid,
  faPaintBrush,
  faPalette,
  faShoppingBasket,
  faThumbsUp,
  faLongArrowDown,
  faUsers,
  fab,
  faFacebook,
  faLinkedin,
  faInstagram,
  faCopyright,
  faDownload,
  faSignIn,
  faLifeRing,
  faBars,
  faTimes,
  faWindowClose
)

const Layout = ({ children }) => (
  <div>
    <Helmet
      title="Realhub"
      meta={[
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1.0',
        },
        {
          name: 'msapplication-TileColor',
          content: '#2d89ef',
        },
        {
          name: 'theme-color',
          content: 'ffffff',
        },
      ]}
    >
      <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
      <link rel="icon" type="image/png" sizes="32x32" href={icon32} />
      <link rel="icon" type="image/png" sizes="16x16" href={icon16} />
      <link rel="mask-icon" href={maskIcon} color="#326270" />
    </Helmet>
    <Navbar />
    <div>{children}</div>
    <Footer />
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
