import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Button from './button'

class TypeFormButton extends Component {
  componentDidMount() {
    const { url } = this.props

    if (typeof window !== 'undefined') {
      const typeformEmbed = require('@typeform/embed')
      this.typeform = typeformEmbed.makePopup(url, {
        mode: 'drawer_left',
      })
    }
  }

  render() {
    const { text, plain } = this.props

    if (plain) {
      return <PlainLink onClick={() => this.typeform.open()}>{text}</PlainLink>
    }

    return <Button text={text} onClick={() => this.typeform.open()} />
  }
}

TypeFormButton.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string,
  plain: PropTypes.bool,
}

TypeFormButton.defaultProps = {
  url: 'https://darrellgardiner.typeform.com/to/DGXZJH',
  text: 'Contact Us',
  plain: false,
}

export default TypeFormButton

const PlainLink = styled.a`
  color: #02b7e2;
  font-family: Caveat;
  font-size: 18px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`
