import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import customMedia from '../../utils/customMedia'

const ContentWrapper = ({
  children,
  direction = 'column',
  style,
  contentRef,
}) => (
  <Wrapper direction={direction} style={style} ref={contentRef}>
    {children}
  </Wrapper>
)

ContentWrapper.propTypes = {
  direction: PropTypes.string,
  style: PropTypes.object,
}

export default ContentWrapper

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${props => props.direction};
  width: 90%;
  margin: 50px auto;
  flex-wrap: wrap;

  ${customMedia.between('mobile', 'tablet')`
    width: 80%;
    margin: 90px auto;
    align-items: flex-start;
    flex-wrap: nowrap;
  `};

  ${customMedia.greaterThan('desktop')`
    width: 768px;
    margin: 90px auto;
    align-items: flex-start;
    flex-wrap: nowrap;
  `};
`
