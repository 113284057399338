import React, { Component } from 'react'
import { navigate } from 'gatsby-link'
import styled, { keyframes, css } from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faSignIn, faBars } from '@fortawesome/pro-light-svg-icons'

import Link from './link'

import logo from '../../images/logo.svg'
import customMedia from '../../utils/customMedia'

const features = [
  { title: 'Services', url: '/services' },
  { title: 'Proposals', url: '/proposals' },
  { title: 'Social Media', url: '/social-media' },
  { title: 'Campaign Management', url: '/campaigns' },
  { title: 'Marketing', url: '/marketing' },
  { title: 'Marketplace', url: '/marketplace' },
  { title: 'Agent/Vendor Mobile', url: '/mobile-agent' },
  { title: 'Brand Management', url: '/brands' },
  { title: 'Finance Tools', url: '/finance' },
  { title: 'Creative Design', url: '/design-content' },
  { title: 'Support', url: '/support' },
  {title: 'Migrating', url: '/migrating'}
]

const partners = [
  { title: 'Customers', url: '/case-studies' },
  { title: 'Technology', url: '/marketplace' },
]

const companyLink = [
  { title: 'About', url: '/about-us' },
  { title: 'Careers', url: 'https://realhub.betterteam.com' },
  { title: 'Blog', url: 'https://blog.realhub.com.au' },
]

const studiosLink = [{ title: 'Services', url: '/realstudios' }]

class Navbar extends Component {
  state = {
    headerLink: '',
    mobileMenu: false,
  }

  _handleClick = title => {
    const { headerLink } = this.state

    this.setState({
      headerLink: headerLink === title ? '' : title,
    })
  }

  _toggleMenu = () => {
    const { mobileMenu } = this.state
    this.setState({
      mobileMenu: !mobileMenu,
      headerLink: '',
    })
  }

  _renderMobileMenu = () => {
    const { mobileMenu, headerLink } = this.state

    return (
      <MobileMenu show={mobileMenu}>
        <Flex>
          <Logo src={logo} onClick={() => navigate('/')} />
          <CloseButton onClick={() => this._toggleMenu()}>
            CLOSE
            <FontAwesomeIcon
              icon={['fal', 'times']}
              style={{ marginLeft: 10 }}
            />
          </CloseButton>
        </Flex>
        <HeaderLinksMobile>
          <HeaderLinkMobile
            onClick={() => this._handleClick('features')}
            active={headerLink === 'features'}
          >
            FEATURES
            <SubLinksMobile active={headerLink === 'features'}>
              {features.map(({ title, url }, index) => (
                <SubLink to={url} key={`nav_features_mobile_${index}`}>
                  {title}
                </SubLink>
              ))}
            </SubLinksMobile>
          </HeaderLinkMobile>
          <HeaderLinkMobile
            onClick={() => this._handleClick('partners')}
            active={headerLink === 'partners'}
          >
            PARTNERS
            <SubLinksMobile active={headerLink === 'partners'}>
              {partners.map(({ title, url }, index) => (
                <SubLink to={url} key={`nav_partners_mobile_${index}`}>
                  {title}
                  <ArrowIcon icon={faArrowRight} />
                </SubLink>
              ))}
            </SubLinksMobile>
          </HeaderLinkMobile>
          <HeaderLinkMobile
            onClick={() => this._handleClick('company')}
            active={headerLink === 'company'}
          >
            COMPANY
            <SubLinksMobile active={headerLink === 'company'}>
              {companyLink.map(({ title, url }, index) => (
                <SubLink to={url} key={`nav_companyLink_mobile_${index}`}>
                  {title}
                  <ArrowIcon icon={faArrowRight} />
                </SubLink>
              ))}
            </SubLinksMobile>
          </HeaderLinkMobile>
          <HeaderLinkMobile
            onClick={() => this._handleClick('studios')}
            active={headerLink === 'studios'}
          >
            STUDIOS
            <SubLinksMobile active={headerLink === 'studios'}>
              {studiosLink.map(({ title, url }, index) => (
                <SubLink to={url} key={`nav_studiosLink_mobile_${index}`}>
                  {title}
                  <ArrowIcon icon={faArrowRight} />
                </SubLink>
              ))}
            </SubLinksMobile>
          </HeaderLinkMobile>
        </HeaderLinksMobile>
        <MobileFooter>
          <LoginLink
            href="https://realhub.realbase.io/"
            style={{ marginLeft: 'auto', display: 'block' }}
          >
            LOGIN
            <SignIcon icon={faSignIn} />
          </LoginLink>
        </MobileFooter>
      </MobileMenu>
    )
  }

  render() {
    return (
      <Wrapper>
        <LogoPart>
          <Logo src={logo} onClick={() => navigate('/')} />
        </LogoPart>

        <HeaderLinks>
          <HeaderItem>
            <HeaderLink to="/services">FEATURES</HeaderLink>
            <SubLinks style={{ width: '280px' }}>
              {features.map(({ title, url }, index) => (
                <SubLink to={url} key={`nav_features_${index}`}>
                  {title}
                  <ArrowIcon icon={faArrowRight} />
                </SubLink>
              ))}
            </SubLinks>
          </HeaderItem>
          <HeaderItem>
            <HeaderLink to="/case-studies">PARTNERS</HeaderLink>
            <SubLinks style={{ width: '150px' }}>
              {partners.map(({ title, url }, index) => (
                <SubLink to={url} key={`nav_partners_${index}`}>
                  {title}
                  <ArrowIcon icon={faArrowRight} />
                </SubLink>
              ))}
            </SubLinks>
          </HeaderItem>
          <HeaderItem>
            <HeaderLink to="/about-us">COMPANY</HeaderLink>
            <SubLinks style={{ width: '150px' }}>
              {companyLink.map(({ title, url }, index) => (
                <SubLink to={url} key={`nav_companyLink_${index}`}>
                  {title}
                  <ArrowIcon icon={faArrowRight} />
                </SubLink>
              ))}
            </SubLinks>
          </HeaderItem>
          <HeaderItem>
            <HeaderLink to="/realstudios">STUDIOS</HeaderLink>

            <SubLinks style={{ width: '150px' }}>
              {studiosLink.map(({ title, url }, index) => (
                <SubLink to={url} key={`nav_studiosLink_${index}`}>
                  {title}
                  <ArrowIcon icon={faArrowRight} />
                </SubLink>
              ))}
            </SubLinks>
          </HeaderItem>
        </HeaderLinks>
        <DesktopRight>
          <LoginLink href="https://realhub.realbase.io/">
            LOGIN
            <SignIcon icon={faSignIn} />
          </LoginLink>
        </DesktopRight>
        <MobileMenuButton onClick={() => this._toggleMenu()}>
          MENU
          <FontAwesomeIcon icon={faBars} style={{ marginLeft: 10 }} />
        </MobileMenuButton>

        {this._renderMobileMenu()}
      </Wrapper>
    )
  }
}

export default Navbar

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 55px;
  padding: 0 5%;
  background-color: transparent;
  z-index: 2;
  font-family: Open Sans;

  ${customMedia.greaterThan('mobile')`
    width: 100%;
    padding: 0 30px;
    margin: 0;
  `};
`

const LogoPart = styled.div`
  ${customMedia.between('mobile', 'tablet')`
    width: 10%;
  `};
  ${customMedia.greaterThan('desktop')`
    width: calc((100% - 768px) / 2);
  `};
`
const Logo = styled.img`
  margin-bottom: 0;
  cursor: pointer;
  filter: brightness(0) invert(1);
`

const DesktopRight = styled.div`
  display: none;

  ${customMedia.between('mobile', 'tablet')`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    min-width: 210px;
  `};

  ${customMedia.greaterThan('desktop')`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    width: 20%;
  `};
`

const SignIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
`

const LoginLink = styled.a`
  display: none;
  color: white;
  padding: 6px 15px;
  text-decoration: none;
  font-size: 12px;
  border-radius: 5px;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.1);
  letter-spacing: 0.1em;

  ${customMedia.greaterThan('mobile')`
    display: block;

    &:hover {
      color: #02B7E2;
      background-color: white;
    }
  `};
`

const ArrowIcon = styled(FontAwesomeIcon)`
  display: none;
  margin-left: 5px;
  color: white;
`

const SubLinks = styled.div`
  display: none;
  position: absolute;
  top: 33px;
  left: 0;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.95);
  border-radius: 10px;

  &::before {
    content: '';
    position: absolute;
    top: -17px;
    left: -10px;
    height: 17px;
    width: 110%;
    z-index: 1;
  }
`

const SubLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 10px 0;
  width: 100%;
  color: white;
  font-weight: 300;
  text-decoration: none;

  &:hover {
    font-weight: 600;

    ${ArrowIcon} {
      display: block;
    }
  }
`

const HeaderLinks = styled.div`
  cursor: pointer;
  display: none;
  color: white;
  margin-bottom: 0;

  ${customMedia.between('mobile', 'tablet')`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 80%;
    font-size: 14px;
  `};

  ${customMedia.greaterThan('desktop')`
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 768px;
  `};
`

const HeaderItem = styled.li`
  position: relative;
  list-style-type: none;
  margin-right: 50px;
  margin-bottom: 0;

  &:hover {
    &::after {
      content: '';
      position: absolute;
      bottom: -12px;
      margin-top: 0;
      left: 18px;
      border: solid 9px transparent;
      border-bottom-color: rgba(0, 0, 0, 0.95);
      z-index: 1;
    }

    ${SubLinks} {
      display: flex;
      flex-direction: column;
    }
  }

  ${customMedia.greaterThan('desktop')`
    width: 90px;
    font-size: 14px;
    letter-spacing: 1px;

    &:hover {
      font-weight: bold;
    }
  `};
`

const HeaderLink = styled(Link)`
  color: white;
  text-decoration: none;
`

const MobileMenuButton = styled.a`
  margin-left: auto;
  padding: 6px 15px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.1em;
  border-radius: 5px;
  color: white;
  background-color: rgba(255, 255, 255, 0.1);

  ${customMedia.greaterThan('mobile')`
    display: none;
  `};
`

const Flex = styled.div`
  display: flex;
`

const sliceRight = keyframes`
  from { 
    opacity: 0; 
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`
const sliceBack = keyframes`
  from { 
    opacity: 1; 

  }
  to {
    opacity: 0;

  }
`

const sliceRightStyle = css`
  animation: ${sliceRight} 0.3s linear;
`

const sliceBackStyle = css`
  animation: ${sliceBack} 0.8s linear;
`

const MobileMenu = styled.div`
  display: ${props => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  padding: 15px 5%;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  ${props => (props.show ? sliceRightStyle : sliceBackStyle)};

  ${customMedia.greaterThan('mobile')`
    display: none;
  `};
`
const CloseButton = styled.a`
  margin-left: auto;
  color: white;
  font-weight: 600;
  letter-spacing: 0.1em;
`

const HeaderLinksMobile = styled.ul`
  padding: 30px 0;
  margin-bottom: 0;
  overflow-y: scroll;
`

const HeaderLinkMobile = styled.li`
  padding: 15px 0;
  margin-bottom: 0;
  color: ${props => (props.active ? '#02b7e2' : 'white')};
  font-weight: 600;
  letter-spacing: 1px;
  list-style-type: none;
`

const SubLinksMobile = styled.div`
  display: ${props => (props.active ? 'flex' : 'none')};
  flex-direction: column;
  padding: 20px 0;
`
const MobileFooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  padding: 10px 0;
`
