import barryPlant from '../../images/brandAgentIcon/doug_hutchinson.jpg'
import cunninghams from '../../images/brandAgentIcon/john_cunningham.jpg'
import mcconnellBourn from '../../images/brandAgentIcon/mathewbourn.jpg'
import LJHooker from '../../images/brandAgentIcon/john_mcmanus.jpg'
import harris from '../../images/brandAgentIcon/phil_harris.jpg'
import belle from '../../images/brandAgentIcon/kristy_salmon.jpg'
import laingSimmons from '../../images/brandAgentIcon/malory_wheeler.jpg'
import upstate from '../../images/brandAgentIcon/ziggy_dehaan.jpg'

export const agentIcon = {
  barryPlant,
  cunninghams,
  mcconnellBourn,
  LJHooker,
  harris,
  belle,
  laingSimmons,
  upstate,
}
