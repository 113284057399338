import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Cui from 'react-cui'
import Img from 'gatsby-image'
import styled from 'styled-components'

import customMedia from '../../utils/customMedia'

const query = graphql`
  query {
    teamChatImg: file(relativePath: { eq: "share/team-chat-callout.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

const SpeakToTeam = ({ uid, avatar, height }) => {
  return (
    <StaticQuery
      query={query}
      render={data => (
        <Wrapper>
          <MeetImg fluid={data.teamChatImg.childImageSharp.fluid} />
          <Cui uid={uid} avatar={avatar} height={height} />
        </Wrapper>
      )}
    />
  )
}

SpeakToTeam.propTypes = {
  avatar: PropTypes.string,
  height: PropTypes.string,
  uid: PropTypes.string,
}

SpeakToTeam.defaultProps = {
  avatar:
  'https://labs-assets.typeform.com/cui/avatars/kdcJ6H/1a988e039322e0939d46.png',
  height: '300',
  uid: 'kdcJ6H',
}

export default SpeakToTeam

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const MeetImg = styled(Img)`
  width: 70%;
  margin: auto;

  ${customMedia.between('mobile', 'tablet')`
     width: 70%;
  `};

  ${customMedia.greaterThan('mobile')`
    width: 50%;
  `};
`
