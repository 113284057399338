import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styled from 'styled-components'

import customMedia from '../../utils/customMedia'

const ImageBackground = props => {
  const {
    background,
    children,
    height,
    imgStyle,
    opacity = '0.75',
    overlayColor,
    skew,
    style,
  } = props

  return (
    <Wrapper height={height} skew={skew} style={style}>
      <BackgroundHolder
        overlayColor={overlayColor}
        skew={skew}
        opacity={opacity}
      >
        <BackgroundImg fluid={background} skew={skew} imgStyle={imgStyle} />
      </BackgroundHolder>
      <Content skew={skew}>{children}</Content>
    </Wrapper>
  )
}

ImageBackground.propTypes = {
  background: PropTypes.object,
  height: PropTypes.string,
  imgStyle: PropTypes.object,
  opacity: PropTypes.string,
  overlayColor: PropTypes.string,
  skew: PropTypes.bool,
  style: PropTypes.object,
}

export default ImageBackground

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: ${props =>
    props.skew ? 'calc(100vh - 55px)' : props.height || '100vh'};
  min-height: 400px;
  margin-bottom: 50px;

  ${customMedia.greaterThan('mobile')`
    height: ${props => props.height || 'calc(100vh - 55px)'};
    max-height: 960px;
  `};
`

const Content = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  color: white;
  z-index: ${props => (props.skew ? '0' : '2')};

  ${customMedia.greaterThan('desktop')`
    max-width: 1024px;
  `};
`

const BackgroundHolder = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: ${props => (props.skew ? 'top left' : 'none')};
  transform: ${props => (props.skew ? 'skewY(-10deg)' : 'none')};
  overflow: hidden;

  & .gatsby-image-wrapper {
    height: 100%;
  }

  & .gatsby-image-wrapper::before {
    content: '';
    position: absolute;
    background: ${props =>
      props.overlayColor || `rgba(0, 0, 0, ${props.opacity})`};
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  ${customMedia.greaterThan('mobile')`
    transform: ${props => (props.skew ? 'skewY(-4deg)' : 'none')};
  `};
`

const BackgroundImg = styled(Img)`
  width: 100%;
  height: 100%;
  transform-origin: ${props => (props.skew ? 'top left' : 'none')};
  transform: ${props => (props.skew ? 'skewY(10deg)' : 'none')};

  ${customMedia.greaterThan('mobile')`
    transform: ${props => (props.skew ? 'skewY(4deg)' : 'none')};
  `};
`
