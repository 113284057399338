import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Link from './link'
import ContentWrapper from './contentWrapper'
import realhub from '../../images/share/realhub.svg'
import customMedia from '../../utils/customMedia'

const solutions = [
  { title: 'Proposals', url: '/proposals' },
  { title: 'Social Media', url: '/social-media' },
  { title: 'Campaign Management', url: '/campaigns' },
  { title: 'Creative Design', url: '/design-content' },
  { title: 'Support', url: '/support' },
]

const companyLink = [
  { title: 'About', url: '/about-us' },
  { title: 'Careers', url: 'https://realhub.betterteam.com' },
  { title: 'Blog', url: 'https://blog.realhub.com.au' },
  { title: 'Privacy', url: '/privacy' },
]

const partners = [
  {
    title: 'Customers',
    url: '/case-studies',
  },
  { title: ' Technology   ', url: '/marketplace' },
]

export default () => (
  <Wrapper>
    <Background />

    <ContentWrapper
      style={{
        margin: '0 auto',
        paddingBottom: '10px',
      }}
    >
      <Flex>
        <Left>
          <LinksDiv>
            <LinksTitle> SOLUTIONS </LinksTitle>
            {solutions.map((solution, i) => {
              return (
                <BaseLink to={solution.url} key={'solution_' + i}>
                  {solution.title}
                </BaseLink>
              )
            })}
          </LinksDiv>
        </Left>
        <Right>
          <LinksDiv style={{ marginRight: '50px', minWidth: '120px' }}>
            <LinksTitle> PARTNERS </LinksTitle>
            {partners.map(({ title, url }, i) => {
              return (
                <BaseLink to={url} key={'partner_' + i}>
                  {title}
                </BaseLink>
              )
            })}
          </LinksDiv>
          <LinksDiv>
            <LinksTitle> COMPANY </LinksTitle>
            {companyLink.map(({ title, url }, i) => {
              return (
                <BaseLink key={'company_' + i} to={url}>
                  {title}
                </BaseLink>
              )
            })}
          </LinksDiv>
        </Right>
      </Flex>
      <Footer>
        <Follow>Follow us:</Follow>
        <Flex>
          <Link to="https://www.linkedin.com/company/realhub/">
            <SocialIcon icon={['fab', 'linkedin']} />
          </Link>
          <Link to="https://www.facebook.com/realhubapp/">
            <SocialIcon icon={['fab', 'facebook']} />
          </Link>
          <Link to="https://www.instagram.com/realhubaus/">
            <SocialIcon icon={['fab', 'instagram']} />
          </Link>
        </Flex>
        <Flex
          style={{
            padding: '15px 0',
            borderTop: '1px solid #E4E7E7',
            marginTop: '15px',
            flexWrap: 'nowrap',
          }}
        >
          <Left
            style={{ alignItems: 'center', marginTop: 0, color: '#8C8C8E' }}
          >
            <Realhub src={realhub} />
            <FontAwesomeIcon
              icon={['fal', 'copyright']}
              style={{ fontSize: '10px', marginRight: '10px' }}
            />
            <RealhubText>Realhub</RealhubText>
          </Left>
          {/* Waiting for the link 
           <Right>
            <DownLoad>
              <FontAwesomeIcon
                icon={['fal', 'download']}
                style={{ marginRight: 5 }}
              />
              Terms
            </DownLoad>
            <DownLoad>
              <FontAwesomeIcon
                icon={['fal', 'download']}
                style={{ marginRight: 5 }}
              />
              Privacy
            </DownLoad>
          </Right>
          */}
        </Flex>
      </Footer>
    </ContentWrapper>
  </Wrapper>
)

const Wrapper = styled.div`
  position: relative;
  display: flex;
  background: #f8f8f8;
  min-height: 360px;
  font-family: Open Sans;

  ${customMedia.greaterThan('mobile')`
    display: flex;
    flex-direction: row;
  `};
`

const Background = styled.div`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  transform-origin: top left;
  transform: skewY(-20deg);
  top: 0;
  bottom: 0;

  ${customMedia.greaterThan('mobile')`
    transform: skewY(-8deg);
  `};
`

const Left = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;

  ${customMedia.greaterThan('mobile')`
      margin-top: 0;
  `};
`
const Right = styled.div`
  display: flex;

  ${customMedia.greaterThan('mobile')`
    margin-left: auto;
  `};
`

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${customMedia.greaterThan('mobile')`
    flex-wrap: nowrap;
  `};
`

const LinksDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  font-size: 12px;
  padding: 10px 0;

  ${customMedia.greaterThan('mobile')`
     width: 100%;
     margin: 10px 0;
  `};
`

const LinksTitle = styled.label`
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 1px;
  color: #404041;
  margin-bottom: 30px;
`

const BaseLink = styled(Link)`
  margin-bottom: 15px;
  font-size: 14px;
  color: #8c8c8c;
  letter-spacing: 1px;
  text-decoration: none;

  &:hover {
    color: #02b7e2;
  }
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  width: 100%;
`

const Follow = styled.div`
  font-size: 12px;
  letter-spacing: 1px;
  color: #676d72;
  margin-bottom: 15px;
`

const SocialIcon = styled(FontAwesomeIcon)`
  font-size: 28px;
  margin-right: 20px;
  color: #8c8c8e;

  ${customMedia.greaterThan('mobile')`
    font-size: 24px;
    margin-right: 10px;
  `};
`

const Realhub = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 10px;
  margin-bottom: 0;
`
const RealhubText = styled.span`
  font-size: 12px;
  letter-spacing: 1px;
  color: #8c8c8e;
`

const DownLoad = styled(Link)`
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 12px;
  text-align: right;
  color: #8c8c8e;
`
