import React, { Fragment } from 'react'
import styled from 'styled-components'
import { brandLogoMedia } from '../components/share/mediaImport'
import { agentIcon } from '../components/share/agentPhotoImport'

const brandsData = [
  {
    iconURL: brandLogoMedia.brandLJHookerIconSvg,
    iconSvg: brandLogoMedia.brandLJHookerIconSvg,
    brandName: 'LJ Hooker',
    agentName: 'John McManus',
    agentRoles: ['Principal/Director', 'LJ Hooker Willoughby Artarmon'],
    agentIcon: agentIcon.LJHooker,
    link: '/case-studies/lj-hooker',
    active: true,
    content: () => (
      <Fragment>
        Coming across to Realhub, they’ve produced something that’s very unique
        for the real estate industry. I believe it’s going to set a benchmark in
        the industry. I think realhub is heading to be the apple of real estate.
        They’ve got young people in the business that are enthusiastic with
        great, new and fresh ideas. I’ve put all my faith in the guys at
        realhub. If you’re going to start looking at becoming a local unique
        company talk to the guys at realhub, it’ll change your business.
      </Fragment>
    ),
  },
  {
    iconURL: brandLogoMedia.brandBellePropertyIconSvg,
    iconSvg: brandLogoMedia.brandBellePropertyIconSvg,
    brandName: 'BelleProperty',
    agentName: 'Kristy Salmon',
    agentRoles: ['Operations Manager', 'Belle Property Group'],
    link: '/case-studies/belle-property',
    agentIcon: agentIcon.belle,
    active: true,
    content: () => (
      <Fragment>
        <Paragraph>
          Seriously you guys rock! And we are so happy to have come across... As
          they say, <strong>if only we had done this earlier.</strong> :)
        </Paragraph>

        <Paragraph>
          Everyone including myself has been over the moon how smoothly
          everything has gone, your communication, ease and flexibility and just
          getting to know everyone up to this point has been amazing.
        </Paragraph>

        <Paragraph>
          You guys know <strong>how to make us feel calm</strong> when we hit
          the go button. I must say this as it’s one of your best team qualities
          - <strong> everything felt easy and under control.</strong>
        </Paragraph>
      </Fragment>
    ),
  },
  {
    iconURL: brandLogoMedia.brandBarryPlantIconSvg,
    iconSvg: brandLogoMedia.brandBarryPlantIconSvg,
    brandName: 'BarryPlant',
    agentName: 'Doug Hutchinson',
    agentRoles: ['Operations Manager', 'Barry Plant Group'],
    agentIcon: agentIcon.barryPlant,
    link: '/case-studies/barry-plant',
    active: true,
    content: () => (
      <Fragment>
        We can already see the{' '}
        <strong>
          improvements and insights in our business by using Realhub{' '}
        </strong>
        as a platform for all our offices.
        <br />
        <br />
        One of our objectives was to be able to collaborate more effectively
        with our offices and provide a much greater level of support at scale
        than we have at the start. Realhub offers us this capacity to work with
        a wide variety of suppliers in our group. We feel Realhub is becoming a
        part of our business and our team. We work closely between our corporate
        marketing team and our offices together with realhub in a collaborative
        environment. <br />
        <br />
        <strong>There's a lot of change occurring,</strong> moving into the new
        year and we can already see the improvements in our business and the
        insights that we are gaining from the platform to increase our
        efficiency and realise the opportunities presented to us. <br />
        <br />
        <strong>
          Realhub is aligned with our business and our philosophies.
        </strong>{' '}
        Prior to Realhub we experienced major inefficiencies across the group,
        in relying on a multitude of supplier systems there was a lot of
        duplication and a lot of time wasting. We're eliminating that time
        wasting through the adoption of Realhub. That's what's going to drive
        value in our business and free our people up to focus on value adding
        activities.
        <br />
        <br /> We didn't have a common platform for our entire brand group.
        Realhub offers the efficient delivery of services in an environment
        where we can work with all our suppliers. <br />
        <br />
        I'm excited for what the future holds for our businesses together
      </Fragment>
    ),
  },
  {
    iconURL: brandLogoMedia.brandHarrisIconSvg,
    iconSvg: brandLogoMedia.brandHarrisIconSvg,
    brandName: 'HARRIS',
    agentName: 'Phil Harris',
    agentRoles: ['Owner/Operator', 'Harris Real Estate'],
    agentIcon: agentIcon.harris,
    active: true,
    content: () => (
      <Fragment>
        <Paragraph>
          We were looking for a marketing program to further{' '}
          <strong> streamline our current processes </strong> and allow our team
          to be more productive.
        </Paragraph>

        <Paragraph>
          Realhub’s speed of communication and ability to quickly make changes
          has been one of their greatest strengths, and allowed our staff to
          focus less on backend procedures.{' '}
          <strong>Their passion for technology </strong>
          is evident as their system is always evolving and adapting to market
          changes. If we have ideas for system improvements we know that
          Realhub’s team will be just as excited as us to build and implement
          new features”
        </Paragraph>
      </Fragment>
    ),
  },
  {
    iconURL: brandLogoMedia.brandCunninghamsIconSvg,
    iconSvg: brandLogoMedia.brandCunninghamsIconSvg,
    brandName: 'Cunninghams',
    agentName: 'John Cunningham',
    agentRoles: ['Director Cunninghams Real Estate', 'President REINSW'],
    agentIcon: agentIcon.cunninghams,
    link: '/case-studies/cunninghams',
    active: true,
    content: () => (
      <Fragment>
        "It was a very big decision, we'd been with a previous supplier over 20
        years. But what was presented to us was{' '}
        <strong> so exciting and refreshing </strong> we couldn't ignore it."
        <br />
        <br />
        The delivery has been above expectation.
        <br />
        <br />
        There's nothing that's too hard.
        <br />
        <br />
        There's <strong> a level of intelligence behind Realhub </strong> that
        makes our jobs that much easier. It's an enhancer to what we do as
        agents on the ground. It's still about relationships with sellers,
        buyers and tenants.
        <br />
        <br />
        Innovation is a really important part of what we do, we're constantly
        looking ahead. How can we do something better. How can we innovate and
        provide better services. Innovation in itself isn't the answer.
        <br />
        <br />
        Sometimes you've got to make a quantum leap, you've gotta take a leap of
        faith with innovation and lead the way.{' '}
        <strong>Change is inevitable.</strong> It's the only constant.{' '}
        <strong>Embrace it, go with it,</strong> because if you don't you'll
        just get left behind
      </Fragment>
    ),
  },
  {
    iconURL: brandLogoMedia.brandMcConnelBournIconSvg,
    iconSvg: brandLogoMedia.brandMcConnelBournIconSvg,
    brandName: 'McConnel Bourn',
    agentName: 'Matthew Bourn',
    agentRoles: ['Principal & Director', 'McConnell Bourn'],
    agentIcon: agentIcon.mcconnellBourn,
    active: true,
    content: () => (
      <Fragment>
        <Paragraph>
          We’re dealing with high end properties. They’re challenging and there
          is a high level of demand and expectations from our clients, but we’ve
          received nothing but praise.
        </Paragraph>

        <Paragraph>
          <strong>We’ve got to adapt to change.</strong>
        </Paragraph>

        <Paragraph>
          To evolve, we have to give the client the experience, advise and
          result they deserve. We wanted to partner with a company that actually
          embraced the way we go about our marketing.
        </Paragraph>

        <Paragraph>
          It’s all about the property and how that property gets used. Our
          partnership is working really really well, and we know that from the
          client feedback to us that they are really enjoying it.
        </Paragraph>

        <Paragraph>
          We’d been looking around for{' '}
          <strong>the right team to work with</strong> for a long time, they
          came to the table with great ideas and together we’ve enhanced the
          saleability of the properties we are listing.
        </Paragraph>

        <Paragraph>
          We’ve grown, we’ve developed and we’ve evolved into a bigger and
          better agency than what we were last year, working with amazing
          clients and{' '}
          <strong>
            an amazing team who work tirelessly and endlessly for our clients
          </strong>
          , supported by the team at Realhub.
        </Paragraph>
      </Fragment>
    ),
  },
  {
    iconURL: brandLogoMedia.brandUpstateIconSvg,
    iconSvg: brandLogoMedia.brandUpstateIconSvg,
    brandName: 'Upstate',
    agentName: 'Ziggy DeHaan',
    agentRoles: ['Marketing Manager', 'Upstate'],
    agentIcon: agentIcon.upstate,
    active: true,
    content: () => (
      <Fragment>
        <Paragraph>
          We have been using Realhub for both our agent and property marketing
          for almost a year now and are <strong>continually impressed</strong>.
          The professionalism and ease of use of the Realhub platform and team
          made working with them an easy transition.
        </Paragraph>

        <Paragraph>
          They take our feedback onboard and{' '}
          <strong>implement changes almost immediately</strong>, with a support
          team that are always available to help. Their{' '}
          <strong>delivery and speed</strong> helps us pitch to vendors and
          takes the stress out of any last minute campaigns. The templates are
          made simple and user friendly, and they are{' '}
          <strong>constantly evolving</strong> to remove any issues that come up
          with their system.
        </Paragraph>

        <Paragraph>
          We recommend Realhub to any business looking for a platform for their
          real estate marketing.
        </Paragraph>
      </Fragment>
    ),
  },
  {
    iconURL: brandLogoMedia.brandRichardsonWrenchIconSvg,
    iconSvg: brandLogoMedia.brandRichardsonWrenchIconSvg,
    brandName: 'Richardson&Wrench',
    agentName: 'Jodi Warwick',
    agentRoles: ['Office Manager', 'Richardson & Wrench St Ives / Turramurra'],
    active: true,
    content: () => (
      <Fragment>
        We first started using Realhub in 2016 at a time when we were happy with
        our existing platform, but when we met the team and saw Realhub for the
        first time we immediately saw the benefits their user friendly platform
        could provide for our business. Realhub has an exceptional understanding
        of our needs, top quality products and a talented design team - they
        seem to know what we want before we do! Everyone in their office is so
        helpful and friendly. Any enquiry we have ever made has been responded
        to within minutes (literally), and nothing is ever too much trouble. We
        couldn’t ask for better partners in selling real estate!
      </Fragment>
    ),
  },
  {
    iconURL: brandLogoMedia.brandLaingSimmonsIconSvg,
    iconSvg: brandLogoMedia.brandLaingSimmonsIconSvg,
    brandName: 'Laing+Simmons',
    agentName: 'Malory Wheeler',
    agentRoles: ['Marketing/Relationships', 'Laing + Simmons'],
    agentIcon: agentIcon.laingSimmons,
    active: true,
    content: () => (
      <Fragment>
        <Paragraph>
          We recommend Realhub to any business looking for a platform for their
          real estate marketing. RealHub have been{' '}
          <strong>a great one stop shop</strong> when it comes to marketing our
          listings and office. From being able to have our marketing items
          packaged for ease of ordering to having our photographers being able
          to log in and directly upload photos to our campaigns, the online
          system is <strong>simple, clean and easy to use. </strong>
        </Paragraph>

        <Paragraph>
          The RealHub team’s <strong>level of service is unparalleled</strong>,
          having used other companies in the past that provide the same products
          I can say without a doubt that the team at RealHub are at{' '}
          <strong>the top of their game</strong> with the service provided to
          their clients.
        </Paragraph>

        <Paragraph>
          No task is too big or small and no question goes unanswered, proof
          that <strong>the team listens to their clients needs</strong> and
          strive to find solutions to all problems / suggestions presented to
          them.
        </Paragraph>

        <Paragraph>
          I would be more than happy to recommend RealHub to any real estate
          office looking to make a move from their current supplier.
        </Paragraph>
      </Fragment>
    ),
  },

  {
    iconURL: brandLogoMedia.brandMortonIconSvg,
    iconSvg: brandLogoMedia.brandMortonIconSvg,
    brandName: 'Morton',
    active: false,
    content: () => <Fragment />,
  },
  {
    iconURL: brandLogoMedia.brandTheAgencyIconSvg,
    iconSvg: brandLogoMedia.brandTheAgencyIconSvg,
    brandName: 'The Agency',
    agentName: '',
    agentRoles: [''],
    agentIcon: null,
    link: '/case-studies/the-agency',
    active: true,
    content: () => <div />,
  },
  {
    iconURL: brandLogoMedia.brandActonIconSvg,
    iconSvg: brandLogoMedia.brandActonIconSvg,
    brandName: 'ACTON',
    agentName: '',
    agentRoles: [''],
    agentIcon: null,
    active: false,
    content: () => <div />,
  },
  {
    iconURL: brandLogoMedia.brandCobdenHaysonIconSvg,
    iconSvg: brandLogoMedia.brandCobdenHaysonIconSvg,
    brandName: 'Cobden & Hayson',
    agentName: '',
    agentRoles: [''],
    agentIcon: null,
    active: false,
    content: () => <div />,
  },
  {
    iconURL: brandLogoMedia.brandChadwickIconSvg,
    iconSvg: brandLogoMedia.brandChadwickIconSvg,
    brandName: 'Chadwick',
    active: false,
    content: () => <div />,
  },

  {
    iconURL: brandLogoMedia.brandHighlandIconSvg,
    iconSvg: brandLogoMedia.brandHighlandIconSvg,
    brandName: 'highland property agents',
    active: false,
    content:
      'I’ve never experienced a change of service provider that was met with Harris',
  },
]

export default brandsData

const Paragraph = styled.p`
  font-family: Open Sans;
  margin-bottom: 30px;
`
