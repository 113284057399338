import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Button = ({ href, onClick, text, style, ghost = false }) => {
  return (
    <Link href={href} onClick={onClick} target="_top" style={style} ghost={ghost}>
      {text}
    </Link>
  )
}

Button.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string.isRequired,
  style: PropTypes.object,
  ghost: PropTypes.bool,
}

export default Button

const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 10px;
  border: 1px solid #02b7e2;
  border-radius: 5px;
  min-width: 179px;
  min-height: 37px;
  font-size: 12px;
  padding: 10px;
  letter-spacing: 2px;
  color: ${props => (props.ghost ? '#02b7e2' : '#ffffff')};
  background: ${props =>
    props.ghost ? 'Transparent' : 'linear-gradient(105deg, #05c0d5, #03a7cf)'};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: #02b7e2;
    color: white;
  }
`
