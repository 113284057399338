import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import customMedia from '../../../utils/customMedia'

const BannerH4 = ({ children, style }) => <Wrapper style={style}>{children}</Wrapper>

BannerH4.propTypes = {
  style: PropTypes.object,
}

export default BannerH4

const Wrapper = styled.h4`
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 15px;

  ${customMedia.between('mobile', 'tablet')`
    font-size: 22px;
  `};

  ${customMedia.greaterThan('desktop')`
    font-size: 26px;
  `};
`
