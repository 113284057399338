import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import customMedia from '../../utils/customMedia'

function calcMargin(boxMargin = '0px') {
  return boxMargin.replace(/px/g, '') * 2 + 'px'
}

function calcBoxLength(
  rowItems = 5,
  boxMargin = '0px',
  adjustHeight = '0px',
  width = '768px'
) {
  return `
    calc((${width} / ${rowItems}) - (${rowItems} - 1) * ${boxMargin} / ${rowItems} - ${adjustHeight})
  `
}

const Box = ({
  background,
  hover = true,
  iconHover,
  iconLength,
  iconSize,
  iconURL,
  iconColor,
  mediaHover,
  name,
  ...style
}) => (
  <Wrapper
    mediaHover={mediaHover}
    iconHover={iconHover}
    hover={hover}
    {...style}
  >
    <Overlay background={background} />
    <Icon
      iconURL={iconURL}
      iconSize={iconSize}
      iconLength={iconLength}
      iconColor={iconColor}
    />
    {name && <Text>{name}</Text>}
  </Wrapper>
)

Box.propTypes = {
  background: PropTypes.string,
  hover: PropTypes.bool,
  iconHover: PropTypes.string,
  iconLength: PropTypes.string,
  iconSize: PropTypes.string,
  iconURL: PropTypes.string,
  iconColor: PropTypes.string,
  mediaHover: PropTypes.string,
  name: PropTypes.string,
}

export default Box

const Overlay = styled.div`
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${props => props.background || 'rgba(0, 0, 0, 0.5)'};
`

const Icon = styled.div`
  background-image: url(${props => props.iconURL});
  width: ${props => (props.iconLength ? `calc(${props.iconLength})` : '75px')};
  height: ${props => (props.iconLength ? `calc(${props.iconLength})` : '75px')};
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 1;
  background-size: ${props => props.iconSize || 'cover'};
  filter: ${props => props.iconColor};

  ${customMedia.between('mobile', 'tablet')`
    width: ${props => props.iconLength || '75px'};
    height: ${props => props.iconLength || '75px'};
    margin-top: 0;
  `};

  ${customMedia.greaterThan('desktop')`
    width: ${props => props.iconLength || '75px'};
    height: ${props => props.iconLength || '75px'};
  `};
`

const Text = styled.label`
  text-align: center;
  letter-spacing: 1px;
  font-size: 0.75rem;
  font-weight: 500;
  z-index: 1;

  ${customMedia.between('mobile', 'tablet')`
    font-size: 0.6rem;
    letter-spacing: 1px;
    margin-top: 0;
    line-height: 1.3;
  `};
  ${customMedia.greaterThan('desktop')`
    font-size: 10px;
    letter-spacing: 1px;
  `};
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 10px;
  margin-right: ${props => props.boxMargin || '0px'};
  margin-bottom: ${props => props.boxMargin || '0px'};
  background-image: url(${props => props.mediaHover});
  background-size: ${props => props.imgSize || 'cover'};
  background-repeat: no-repeat;
  background-position: center center;
  color: ${props => props.color || '#ffffff'};
  width: ${props => calcBoxLength(2, props.boxMargin, '0px', '90vw')};
  height: ${props =>
    calcBoxLength(2, props.boxMargin, props.adjustHeight, '90vw')};

  ${customMedia.lessThan('mobile')`
    &:nth-child(2n) {
      margin-right: 0;
    };
  `};

  ${customMedia.between('mobile', 'tablet')`
    width: ${props => calcBoxLength(props.rowItems, props.boxMargin)};
    height: ${props =>
      calcBoxLength(props.rowItems, props.boxMargin, props.adjustHeight)};

    &:nth-child(${props => props.rowItems}n) {
      margin-right: 0;
    };
  `};

  ${customMedia.greaterThan('desktop')`
    width: ${props => calcBoxLength(props.rowItems, props.boxMargin)};
    height: ${props =>
      calcBoxLength(props.rowItems, props.boxMargin, props.adjustHeight)};

    &:nth-child(${props => props.rowItems}n) {
        margin-right: 0;
    }

    &:hover {
      color: black;
      cursor: pointer;
    };
  
    &:hover > ${Icon} {
      background-image: ${props =>
        props.hover ? `url(${props.iconHover})` : null});
    };
  
    &:hover > ${Overlay} {
      display: ${props => (props.hover ? 'none' : 'block')};
    };

    &:hover > ${Text} {
      color: #FFFFFF;
    };
  `};
`
