import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Link from '../share/link'

import customMedia from '../../utils/customMedia'

const IntroRow = props => {
  const {
    image,
    title,
    content,
    contentImage,
    descWidth,
    descStyle,
    imgHeight,
    link,
    linkIcon,
    linkText,
    reverse,
    videoId,
  } = props

  return (
    <Wrapper reverse={reverse}>
      <Description descWidth={descWidth} style={descStyle}>
        <Title>{title}</Title>
        {contentImage && (
          <ContentImage
            fluid={contentImage}
            imgStyle={{ objectFit: 'contain' }}
            reverse={reverse}
            imgHeight={imgHeight}
          />
        )}
        <Content>{content}</Content>
        <DescLink to={link || '#'}>
          {linkText}
          {linkIcon && (
            <FontAwesomeIcon
              icon={['fal', 'arrow-right']}
              style={{
                width: '16x',
                height: '16px',
                marginBottom: 0,
                marginLeft: '10px',
              }}
            />
          )}
        </DescLink>
      </Description>
      {image && (
        <ImgPart
          fluid={image}
          imgStyle={{ objectFit: 'contain' }}
          reverse={reverse}
          imgHeight={imgHeight}
        />
      )}
      {videoId && (
        <Vimeo>
          <Iframe
            src={`https://player.vimeo.com/video/${videoId}?color=ffffff&byline=0&portrait=0`}
            frameBorder="0"
            title="realhub intro video"
            allowFullScreen
          />
        </Vimeo>
      )}
    </Wrapper>
  )
}

IntroRow.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  contentImage: PropTypes.object,
  descStyle: PropTypes.object,
  descWidth: PropTypes.string,
  image: PropTypes.object,
  link: PropTypes.string,
  linkIcon: PropTypes.bool,
  linkText: PropTypes.string,
  reverse: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  videoId: PropTypes.string,
}

export default IntroRow

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${customMedia.greaterThan('mobile')`
    margin-bottom: 0;
    flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
  `};
`

const ImgPart = styled(Img)`
  width: 100%;
  max-height: 250px;
  align-self: center;

  ${customMedia.greaterThan('mobile')`
    display: black;
    width: 50%;
    height: ${props => props.imgHeight || '100%'};
    max-height: ${props => props.imgHeight || '100%'};
    margin: ${props =>
      props.reverse ? '30px 40px 30px 0' : '30px 0 30px 40px'};
  `};
`

const Description = styled.article`
  width: 100%;
  margin-bottom: 30px;
  align-self: center;

  ${customMedia.greaterThan('mobile')`
    width: ${props => props.descWidth || '70%'}
    margin-bottom: 0;
  `};
`

const Title = styled.h6`
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 30px;
`

const ContentImage = styled(Img)`
  width: 100%;
  max-height: 120px;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    width: 50%;
    max-height: 120px;
  `};
`

const Content = styled.div`
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    font-size: 14px;
  `};
`

const DescLink = styled(Link)`
  font-family: Caveat;
  color: #02b7e2;
  font-size: 20px;
  letter-spacing: 1px;

  &:hover {
    text-decoration: underline;
  }

  ${customMedia.greaterThan('mobile')`
    text-decoration: none;
  `};
`

const Vimeo = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  max-height: 250px;
  width: 100%;
  align-self: center;

  ${customMedia.greaterThan('mobile')`
    padding-top: 0;
    width: 50%;
    height: 250px;
    margin: ${props =>
      props.reverse ? '30px 40px 30px 0' : '30px 0 30px 40px'};
  `};
`

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  border: 0;
  left: 0;
  height: 100%;
  width: 100%;
`
