import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Box from './box'

import customMedia from '../../utils/customMedia'

const Boxes = ({
  name,
  items,
  clickBox,
  background,
  iconLength,
  iconColor,
  hover,
  ...style
}) => {
  return (
    <Wrapper {...style}>
      {items.map((item, i) => {
        return (
          <Box
            name={item.title}
            key={`${name}_${i}`}
            onClick={() =>
              clickBox && item.active ? clickBox(i, item.title) : undefined
            }
            mediaHover={item.mediaHover}
            iconURL={item.iconURL}
            iconHover={item.iconHover}
            background={item.staging ? '#ffffff' : background}
            iconLength={iconLength}
            iconColor={iconColor}
            hover={hover}
            {...style}
          />
        )
      })}
    </Wrapper>
  )
}

Boxes.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  clickBox: PropTypes.func,
  background: PropTypes.string,
  iconLength: PropTypes.string,
}

export default Boxes

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;

  ${customMedia.greaterThan('desktop')`
    justify-content: ${props => props.align || 'flex-start'};
  `};
`
