import productArtworkBuilderHover from '../../images/ProductTiles/hover/ArtworkBuilder.jpg'
import productArtworkBuilderIcon from '../../images/ProductTiles/icons/normal/ArtworkBuilder.svg'
import productArtworkBuilderIconHover from '../../images/ProductTiles/icons/hover/ArtworkBuilder.svg'

import productBrandProtectionHover from '../../images/ProductTiles/hover/BrandProtection.jpg'
import productBrandProtectionIcon from '../../images/ProductTiles/icons/normal/BrandProtection.svg'
import productBrandProtectionIconHover from '../../images/ProductTiles/icons/hover/BrandProtection.svg'

import productBrandWideReportingHover from '../../images/ProductTiles/hover/BrandWideReporting.jpg'
import productBrandWideReportingIcon from '../../images/ProductTiles/icons/normal/BrandWideReporting.svg'
import productBrandWideReportingIconHover from '../../images/ProductTiles/icons/hover/BrandWideReporting.svg'

import productCampaignManagementHover from '../../images/ProductTiles/hover/CampaignManagement.jpg'
import productCampaignManagementIcon from '../../images/ProductTiles/icons/normal/CampaignManagement.svg'
import productCampaignManagementIconHover from '../../images/ProductTiles/icons/hover/CampaignManagement.svg'

import productDeadlineAssistantHover from '../../images/ProductTiles/hover/DeadlineAssistant.jpg'
import productDeadlineAssistantIcon from '../../images/ProductTiles/icons/normal/DeadlineAssistant.svg'
import productDeadlineAssistantIconHover from '../../images/ProductTiles/icons/hover/DeadlineAssistant.svg'

import productInvoicingAndAccountingHover from '../../images/ProductTiles/hover/InvoicingAndAccounting.jpg'
import productInvoicingAndAccountingIcon from '../../images/ProductTiles/icons/normal/InvoicingAndAccounting.svg'
import productInvoicingAndAccountingIconHover from '../../images/ProductTiles/icons/hover/InvoicingAndAccounting.svg'

import productNewspaperHover from '../../images/ProductTiles/hover/Newspaper.jpg'
import productNewspaperIcon from '../../images/ProductTiles/icons/normal/Newspaper.svg'
import productNewspaperIconHover from '../../images/ProductTiles/icons/hover/Newspaper.svg'

import productOneClickPortalsHover from '../../images/ProductTiles/hover/OneClickPortals.jpg'
import productOneClickPortalsIcon from '../../images/ProductTiles/icons/normal/OneClickPortals.svg'
import productOneClickPortalsIconHover from '../../images/ProductTiles/icons/hover/OneClickPortals.svg'

import productPrintManagementHover from '../../images/ProductTiles/hover/PrintManagement.jpg'
import productPrintManagementIcon from '../../images/ProductTiles/icons/normal/PrintManagement.svg'
import productPrintManagementIconHover from '../../images/ProductTiles/icons/hover/PrintManagement.svg'

import productProposalBuilderHover from '../../images/ProductTiles/hover/ProposalBuilder.jpg'
import productProposalBuilderIcon from '../../images/ProductTiles/icons/normal/ProposalBuilder.svg'
import productProposalBuilderIconHover from '../../images/ProductTiles/icons/hover/ProposalBuilder.svg'

import productQuotingHover from '../../images/ProductTiles/hover/Quoting.jpg'
import productQuotingIcon from '../../images/ProductTiles/icons/normal/Quoting.svg'
import productQuotingIconHover from '../../images/ProductTiles/icons/hover/Quoting.svg'

import productToolkitHover from '../../images/ProductTiles/hover/Toolkit.jpg'
import productToolkitIcon from '../../images/ProductTiles/icons/normal/Toolkit.svg'
import productToolkitIconHover from '../../images/ProductTiles/icons/hover/Toolkit.svg'

import serviceNewspaperHover from '../../images/ServiceTiles/hover/Newspaper.jpg'
import serviceNewspaperIcon from '../../images/ServiceTiles/icons/normal/Newspaper.svg'
import serviceNewspaperIconHover from '../../images/ServiceTiles/icons/hover/Newspaper.svg'

import serviceServicesHover from '../../images/ServiceTiles/hover/Services.jpg'
import serviceServicesIcon from '../../images/ServiceTiles/icons/normal/Services.svg'
import serviceServicesIconHover from '../../images/ServiceTiles/icons/hover/Services.svg'

import serviceSocialMediaHover from '../../images/ServiceTiles/hover/SocialMedia.jpg'
import serviceSocialMediaIcon from '../../images/ServiceTiles/icons/normal/SocialMedia.svg'
import serviceSocialMediaIconHover from '../../images/ServiceTiles/icons/hover/SocialMedia.svg'

import serviceVideoHover from '../../images/ServiceTiles/hover/Video.jpg'
import serviceVideoIcon from '../../images/ServiceTiles/icons/normal/Video.svg'
import serviceVideoIconHover from '../../images/ServiceTiles/icons/hover/Video.svg'

import serviceWebHover from '../../images/ServiceTiles/hover/Web.jpg'
import serviceWebIcon from '../../images/ServiceTiles/icons/normal/Web.svg'
import serviceWebIconHover from '../../images/ServiceTiles/icons/hover/Web.svg'

import servicePrintHover from '../../images/ServiceTiles/hover/Print.jpg'
import servicePrintIcon from '../../images/ServiceTiles/icons/normal/Print.svg'
import servicePrintIconHover from '../../images/ServiceTiles/icons/hover/Print.svg'

import servicePhotographyHover from '../../images/ServiceTiles/hover/Photography.jpg'
import servicePhotographyIcon from '../../images/ServiceTiles/icons/normal/Photography.svg'
import servicePhotographyIconHover from '../../images/ServiceTiles/icons/hover/Photography.svg'

import serviceAgentMarketingHover from '../../images/ServiceTiles/hover/AgentMarketing.jpg'
import serviceAgentMarketingIcon from '../../images/ServiceTiles/icons/normal/AgentMarketing.svg'
import serviceAgentMarketingIconHover from '../../images/ServiceTiles/icons/hover/AgentMarketing.svg'

import brandActonIcon from '../../images/brandLogos/acton.png'
import brandBarryPlantIcon from '../../images/brandLogos/barryPlant.png'
import brandBellePropertyIcon from '../../images/brandLogos/belleProperty.png'
import brandChadwickIcon from '../../images/brandLogos/chadwick.png'
import brandCunninghamsIcon from '../../images/brandLogos/cunninghams.png'
import brandCobdenHaysonIcon from '../../images/brandLogos/cobdenHayson.png'
import brandHarrisIcon from '../../images/brandLogos/harris.png'
import brandHighlandIcon from '../../images/brandLogos/highland.png'
import brandLaingSimmonsIcon from '../../images/brandLogos/laingSimmons.png'
import brandLJHookerIcon from '../../images/brandLogos/ljHooker.png'
import brandMcConnelBournIcon from '../../images/brandLogos/mcconnellBourn.png'
import brandMortonIcon from '../../images/brandLogos/morton.png'
import brandRichardsonWrenchIcon from '../../images/brandLogos/richardsonWrench.png'
import brandTheAgencyIcon from '../../images/brandLogos/theAgency.png'

import brandActonIconSvg from '../../images/brandLogos/acton.svg'
import brandAvnuIconSvg from '../../images/brandLogos/avnu.svg'
import brandBarryPlantIconSvg from '../../images/brandLogos/barryPlant.svg'
import brandBellePropertyIconSvg from '../../images/brandLogos/belleProperty.svg'
import brandChadwickIconSvg from '../../images/brandLogos/chadwick.svg'
import brandCobdenHaysonIconSvg from '../../images/brandLogos/cobdenHayson.svg'
import brandCunninghamsIconSvg from '../../images/brandLogos/cunninghams.svg'
import brandHarrisIconSvg from '../../images/brandLogos/harris.svg'
import brandHighlandIconSvg from '../../images/brandLogos/highland.svg'
import brandLJHookerIconSvg from '../../images/brandLogos/ljHooker.svg'
import brandLaingSimmonsIconSvg from '../../images/brandLogos/laingSimmons.svg'
import brandMcConnelBournIconSvg from '../../images/brandLogos/mcconnellBourn.svg'
import brandMortonIconSvg from '../../images/brandLogos/morton.svg'
import brandRichardsonWrenchIconSvg from '../../images/brandLogos/richardsonWrench.svg'
import brandTheAgencyIconSvg from '../../images/brandLogos/theAgency.svg'
import brandUpstateIconSvg from '../../images/brandLogos/upstate.svg'

import contentChangeImage from '../../images/background/ContentChange.png'
import contentGrowthImage from '../../images/background/ContentGrowth.png'

export const productMedia = {
  productArtworkBuilderHover,
  productArtworkBuilderIcon,
  productArtworkBuilderIconHover,
  productBrandProtectionHover,
  productBrandProtectionIcon,
  productBrandProtectionIconHover,
  productBrandWideReportingHover,
  productBrandWideReportingIcon,
  productBrandWideReportingIconHover,
  productCampaignManagementHover,
  productCampaignManagementIcon,
  productCampaignManagementIconHover,
  productDeadlineAssistantHover,
  productDeadlineAssistantIcon,
  productDeadlineAssistantIconHover,
  productInvoicingAndAccountingHover,
  productInvoicingAndAccountingIcon,
  productInvoicingAndAccountingIconHover,
  productNewspaperHover,
  productNewspaperIcon,
  productNewspaperIconHover,
  productOneClickPortalsHover,
  productOneClickPortalsIcon,
  productOneClickPortalsIconHover,
  productPrintManagementHover,
  productPrintManagementIcon,
  productPrintManagementIconHover,
  productProposalBuilderHover,
  productProposalBuilderIcon,
  productProposalBuilderIconHover,
  productQuotingHover,
  productQuotingIcon,
  productQuotingIconHover,
  productToolkitHover,
  productToolkitIcon,
  productToolkitIconHover,
}

export const servicesMedia = {
  serviceNewspaperHover,
  serviceNewspaperIcon,
  serviceNewspaperIconHover,
  serviceServicesHover,
  serviceServicesIcon,
  serviceServicesIconHover,
  serviceSocialMediaHover,
  serviceSocialMediaIcon,
  serviceSocialMediaIconHover,
  serviceVideoHover,
  serviceVideoIcon,
  serviceVideoIconHover,
  serviceWebHover,
  serviceWebIcon,
  serviceWebIconHover,
  servicePrintHover,
  servicePrintIcon,
  servicePrintIconHover,
  servicePhotographyHover,
  servicePhotographyIcon,
  servicePhotographyIconHover,
  serviceAgentMarketingHover,
  serviceAgentMarketingIcon,
  serviceAgentMarketingIconHover,
}

export const brandLogoMedia = {
  brandActonIcon,
  brandActonIconSvg,
  brandAvnuIconSvg,
  brandBarryPlantIcon,
  brandBarryPlantIconSvg,
  brandBellePropertyIcon,
  brandBellePropertyIconSvg,
  brandChadwickIcon,
  brandChadwickIconSvg,
  brandCobdenHaysonIcon,
  brandCobdenHaysonIconSvg,
  brandCunninghamsIcon,
  brandCunninghamsIconSvg,
  brandHarrisIcon,
  brandHarrisIconSvg,
  brandHighlandIcon,
  brandHighlandIconSvg,
  brandLJHookerIcon,
  brandLJHookerIconSvg,
  brandLaingSimmonsIcon,
  brandLaingSimmonsIconSvg,
  brandMcConnelBournIcon,
  brandMcConnelBournIconSvg,
  brandMortonIcon,
  brandMortonIconSvg,
  brandRichardsonWrenchIcon,
  brandRichardsonWrenchIconSvg,
  brandTheAgencyIcon,
  brandTheAgencyIconSvg,
  brandUpstateIconSvg,
}

export const contentMedia = {
  contentChangeImage,
  contentGrowthImage,
}
